import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    ProductList,
    ProductListProps
} from 'common/components/organisms/ProductList'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { CampaignFeature } from '@irishlife/ilgroupdesignsystem.organisms.campaign-feature'
import { getCampaignFeatureProps } from 'common/utils/geCampaignFeatureProps'

export interface IntroductionProps {
    featureList: FeatureListProps
    featureList2: FeatureListProps
    jumbotron: JumbotronProps
    layout: LayoutProps
    productList: ProductListProps
    secondCallToAction: CampaignFeatureProps
}

export const Introduction: React.FC<IntroductionProps> = ({
    featureList,
    featureList2,
    jumbotron,
    layout,
    secondCallToAction,
    productList
}) => {

    const desktop = useDesktop()
    const campaignFeatureProps = getCampaignFeatureProps(secondCallToAction)

    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            {featureList2.title && (
                <Wrapper component='section'>
                    <Spacer variant={desktop ? 'sm' : 'md'} />
                    <FeatureList variant='right' {...featureList2} />
                </Wrapper>
            )}
            {featureList.title && (
                <Wrapper component='section'>
                    <Spacer variant={desktop ? 'sm' : 'md'} />
                    <FeatureList variant='top' {...featureList} />
                </Wrapper>
            )}
            {productList && (
                <section>
                    <ProductList {...productList} />
                </section>
            )}
            {secondCallToAction.title && (
                <section>
                    <CampaignFeature {...campaignFeatureProps} />
                </section>
            )}
        </Layout>
    )
}
