import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { Introduction } from 'common/components/templates/Introduction'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface IntroductionPageProps {
    pageContext: any
}

export const IntroductionPage: React.FC<IntroductionPageProps> = ({
    pageContext
}) => {
    return <Introduction {...getIntroductionData(pageContext)} />
}

export default IntroductionPage
export const getIntroductionData = (data: any) => {
    return {
        layout: getLayoutData(data),
        featureList: {
            features: data.features,
            image: data.image,
            subtitle: data.features_subtitle,
            title: data.features_title
        },
        featureList2: {
            text: data.featurelist2__text,
            cta: data.featurelist2__cta,
            callToAction: {
                analyticsId: data.featurelist2__cta_analyticsid,
                label: data.featurelist2__cta_label,
                [`${data.call_to_action__iconlocation}Icon`]: data.featurelist2__cta_icon,
                url: data.featurelist2__cta_url,
                variant: data.featurelist2__cta_variant,
                noSpacer: true
            },
            image: data.featurelist2__image,
            title: data.featurelist2__title,
            subtitle: data.featurelist2__subtitle,
            features: data.featurelist2__features
        },
        productList: {
            heading: data.productlist_heading,
            text: data.productlist_text,
            title: data.productlist_title,
            products: (data.products as Array<any>).map((product: any) => ({
                callToAction: {
                    label: product.card_cta_0396ba7__cta_label,
                    variant: product.card_cta_0396ba7__cta_variant,
                    url: product.card_cta_0396ba7__cta_url
                },
                icon: product.productlist__icon,
                title: product.jumbotron__title,
                text: product.productlist__summary
            }))
        },
        jumbotron: {
            ...getJumbotronData(data)
        },
        secondCallToAction: {
            title: data.title_campaign,
            text: data.text_campaign,
            image: data.image_campaign,
            callToAction: {
                analyticsId: data.call_to_action__analytics_id,
                label: data.call_to_action__label,
                [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
                text: data.call_to_action__text,
                title: data.call_to_action__title,
                url: data.call_to_action__url,
                variant: data.call_to_action__variant
            }
        }
    }
}
